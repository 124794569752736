export const globalConcertsEng = [
    {
        id: 1001,
        name: "FNC BAND KINGDOM 2024 - Japan",
        location: "Chiba Makuhari Event Hall",
        date: ["2024-07-13(Sat)", "2024-07-14(Sun)"],
        startTime: "16:00",
        durationMinutes: 270,
        type: "Concert",
        performanceType: "Guest",
        artists: ["N.Flying", "FTISLAND", "CNBLUE", "Hi-Fi Un!corn"],
        poster: "/image/show/world/240713_BAND_KINGDOM_2024_JP.png",
        ticketOpen: { date: "2024-06-29", time: "10:00" },
        ticketLink: "https://nflying-official.jp/news/detail/698",
        lat: "35.6482438",
        lng: "140.0321482",
    },
    {
        id: 1002,
        name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN - Yokohama",
        location: "KT Zepp Yokohama",
        date: ["2024-10-29(Tue)"],
        startTime: "19:00",
        durationMinutes: 160,
        type: "Concert",
        performanceType: "Solo",
        artists: ["N.Flying"],
        poster: "/image/show/world/241029-241101_HIDE-OUT_JP.png",
        ticketOpen: { date: "2024-10-11", time: "19:00" },
        ticketLink: "https://nflying-official.jp/news/detail/731",
        lat: "35.4598951",
        lng: "139.6260000",
    },
    {
        id: 1003,
        name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN - Nagoya",
        location: "Nagoya Bottom Line",
        date: ["2024-10-31(Thu)"],
        startTime: "19:00",
        durationMinutes: 160,
        type: "Concert",
        performanceType: "Solo",
        artists: ["N.Flying"],
        poster: "/image/show/world/241029-241101_HIDE-OUT_JP.png",
        ticketOpen: { date: "2024-10-11", time: "19:00" },
        ticketLink: "https://nflying-official.jp/news/detail/731",
        lat: "35.1700072",
        lng: "136.93860476",
    },
    {
        id: 1004,
        name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN - Osaka",
        location: "Zepp Namba",
        date: ["2024-11-01(Fri)"],
        startTime: "19:00",
        durationMinutes: 120,
        type: "Concert",
        performanceType: "Solo",
        artists: ["N.Flying"],
        poster: "/image/show/world/241029-241101_HIDE-OUT_JP.png",
        ticketOpen: { date: "2024-10-11", time: "19:00" },
        ticketLink: "https://nflying-official.jp/news/detail/731",
        lat: "34.6575657",
        lng: "135.501331",
    },
    {
        id: 1005,
        name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA - Bangkok",
        location: "MCC HALL BANGKAE",
        date: ["2024-11-23(Sat)"],
        startTime: "18:00",
        durationMinutes: 120,
        type: "Concert",
        performanceType: "Solo",
        artists: ["N.Flying"],
        poster: "/image/show/world/241123_HIDE-OUT_BANKOK.jpg",
        ticketOpen: { date: "2024-10-05", time: "14:00" }, // 현지시각 12시 (시차 +2h)
        ticketLink: "https://www.thaiticketmajor.com/concert/2024-nflying-live-hide-out-in-bangkok.html?utm_source=intro&utm_medium=banner&utm_campaign=NFlying2024BKK",
        lat: "13.71440298",
        lng: "100.4068519",
    },
    {
        id: 1006,
        name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA - Kuala Lumpur",
        location: "ZEPP KUALA LUMPUR",
        date: ["2024-12-01(Sun)"],
        startTime: "18:00",
        durationMinutes: 120,
        type: "Concert",
        performanceType: "Solo",
        artists: ["N.Flying"],
        poster: "/image/show/world/241201_HIDE-OUT_MY.jpg",
        ticketOpen: { date: "2024-09-27", time: "12:00" }, // 현지시각 11시 (시차 +1h)
        ticketLink: "https://my.bookmyshow.com/events/2024-n.flying-live-‘hide-out’-in-kuala-lumpur/NFKL2024",
        lat: "3.1397000",
        lng: "101.7093000",
    },
    {
        id: 1007,
        name: "2024 FNC KINGDOM - SING SING SING - Japan",
        location: "Makuhari Messe International Exhibition Hall 9-10",
        date: ["2024-12-14(Sat)", "2024-12-15(Sun)"],
        startTime: "16:00",
        durationMinutes: 120,
        type: "Concert",
        performanceType: "Guest",
        artists: ["N.Flying"],
        poster: "/image/show/world/241214_BAND_KINGDOM_SING3_JP.png",
        ticketOpen: { date: "2024-08-01", time: "18:00" },
        ticketLink: "https://w.pia.jp/t/fnckingdom24/",
        // todo 티켓피아 독점 선행 11/16 까지
        lat: "35.6500363",
        lng: "140.0365264",
    },
    {
        id: 1008,
        name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA - Macau",
        location: "Broadway Theatre, Broadway Macau",
        date: ["2024-12-22(Sun)"],
        startTime: "19:00",
        durationMinutes: 120,
        type: "Concert",
        performanceType: "Solo",
        artists: ["N.Flying"],
        poster: "/image/show/world/241222_HIDE-OUT_MACAU.jpeg",
        ticketOpen: { date: "2024-10-29", time: "13:00" }, // 현지시각 12시 (시차 +1h)
        ticketLink: "https://fncent.com/?m=bbs&bid=korea&mod=view&r=NFLYING&cat=2&uid=60295", // 예매 페이지 3곳이라 공지로 연결함
        lat: "22.14708",
        lng: "113.5520586",
    },
    {
        id: 1009,
        name: "1st FNC BAND KINGDOM in TAOYUAN - Taiwan",
        location: "THE TAOYUAN ARENA",
        date: ["2025-01-04(Sat)", "2025-01-05(Sun)"],
        startTime: "15:30",
        durationMinutes: 0,
        // todo 공연 진행 시간 미정
        type: "Concert",
        performanceType: "Guest",
        artists: ["N.Flying", "FTISLAND", "CNBLUE", "Hi-Fi Un!corn"],
        poster: "/image/show/world/250104_1st_KINGDOM_TAOYUAN.png",
        ticketOpen: { date: "2024-08-24", time: "14:00" }, // 현지시각 13시 (시차 +1h)
        ticketLink: "https://ticketplus.com.tw/activity/f828edd61c11359cc3f3b991e8bcb4d5",
        lat: "24.9934617",
        lng: "121.3238273",
    },
    {
        id: 1010,
        name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA - TAIPEI",
        location: "TAIPEI TICC",
        date: ["2025-02-09(Sun)"],
        startTime: "18:00",
        durationMinutes: 120,
        type: "Concert",
        performanceType: "Solo",
        artists: ["N.Flying"],
        poster: "/image/show/world/250209_HIDE-OUT_TAIPEI_OFFICIAL.jpeg",
        ticketOpen: { date: "2024-11-16", time: "13:00" },
        ticketLink: "https://kktix.com/events/1acbe4df/registrations/new",
        lat: "25.0335925",
        lng: "121.5609091",
    },
];
