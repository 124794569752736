export const globalShowInfosEng = [
  {
    id: 1001,
    name: "FNC BAND KINGDOM 2024 - Japan",
    address:
      "Makuhari Event Hall 2 Chome Nakase, Mihama Ward, Chiba, 261-0023, Japan",
    note: [""],
    capacity: "Maximum capacity of 9,000 people",
    seats: [""],
    setlist: [
      "Kick-Ass",
      "Songbird",
      "Star",
      "Endless Summer",
      "옥탑방 (Rooftop)",
      "Stand by me",
      "I'm Sorry - Hweseung with CNBLUE",
      "바래 (I Wish) - Seunghyub with FTISLAND"
    ],
    ootd: [
      "/image/show_detail/ootd/world/240713_KINGDOM_DAY1.jpeg",
      "/image/show_detail/ootd/world/240714_KINGDOM_DAY2.jpeg",
    ],
  },
  {
    id: 1002,
    name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN - Yokohama",
    address:
      "T Zepp Yokohama 4 Chome-3-6 Minatomirai, Nishi Ward, Yokohama, Kanagawa, 220-0012 Japan",
    note: [""],
    capacity:
      "1st floor: Maximum 1,630 people (standing) / 2nd floor: Approximately 516 people (fixed seating)",
    seats: ["/image/show_detail/seats/world/241029_KT_Zepp_YOKOHAMA_SEAT.png"],
    setlist: [
      "불놀이 (Flowerwork)",
      "Video Therapy",
      "Born to be - 미발매곡",
      "Star",
      "꽃바람 (YOUTH)",
      "아 진짜요. (Oh really.)",
      "Blue Moon",
      "옥탑방 (Rooftop)",
      "그랬나봐 (I Think I Did)",
      "FLOWER FANTASY",
      "Love You Like That - 미발매곡",
      "굿밤 (GOOD BAM)",
      "ANYWAY",
      "4242",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "Kick-Ass",
      "Lupin",
      "Songbird",
      "Moonshot",
      "Monster",
      "진짜가 나타났다 (The Real)",
      "Run",
      "The World Is Mine",
      "그러니까 우리 (Don't Forget This)",
      "Stand By Me",
      "Sunset",
      "폭망 (I Like You)",
      "놔 (Leave It)",
      "Blue Moon",
    ],
    ootd: [
      "/image/show_detail/ootd/world/241029_HIDE-OUT_JAPAN_OOTD_01.jpeg",
      "/image/show_detail/ootd/world/241029_HIDE-OUT_JAPAN_OOTD_02.jpeg",
      "/image/show_detail/ootd/world/241029_HIDE-OUT_JAPAN_OOTD_03.jpeg"
    ],
  },
  {
    id: 1003,
    name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN - Nagoya",
    address:
      "THE BOTTOM LINE 4-7-11 Imaike, Chikusa Ward, Nagoya, Aichi 464-0850, Japan",
    note: ["Seung Hyub's birthday"],
    capacity: "Approximately 750 people",
    seats: [
      "/image/show_detail/seats/world/241031_NAGOYA BOTTOM LINE_MAP1.png",
      "/image/show_detail/seats/world/241031_NAGOYA BOTTOM LINE_MAP2.png",
    ],
    setlist: [
      "불놀이 (Flowerwork)",
      "Video Therapy",
      "Born to be - 미발매곡",
      "Kick-Ass",
      "Lupin",
      "Songbird",
      "Blue Moon",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "옥탑방 (Rooftop)",
      "그랬나봐 (I Think I Did)",
      "FLOWER FANTASY",
      "Love You Like That - 미발매곡",
      "굿밤 (GOOD BAM)",
      "ANYWAY",
      "4242",
      "Star",
      "꽃바람 (YOUTH)",
      "아 진짜요. (Oh really.)",
      "Moonshot",
      "Monster",
      "진짜가 나타났다 (The Real)",
      "Run",
      "The World Is Mine",
      "그러니까 우리 (Don't Forget This)",
      "Stand By Me",
      "Sunset",
      "폭망 (I Like You)",
      "놔 (Leave It)",
      "Blue Moon"
    ],
    ootd: [
      "/image/show_detail/ootd/world/241031_HIDE-OUT_JAPAN_OOTD_00.jpeg",
      "/image/show_detail/ootd/world/241031_HIDE-OUT_JAPAN_OOTD_01.jpeg",
      "/image/show_detail/ootd/world/241031_HIDE-OUT_JAPAN_OOTD_02.jpeg"
    ],
  },
  {
    id: 1004,
    name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN - Osaka",
    address:
      "Zepp Namba 2 Chome-1-39 Shikitsuhigashi, Naniwa Ward, Osaka, 556-0012 Japan",
    note: [""],
    capacity:
      "Maximum 2,530 people - 1st floor 2,090 people (standing) / 2nd floor 440 people (seated)",
    seats: [
      "/image/show_detail/seats/world/241101_ZEPP NAMBA_SEAT_1F.png",
      "/image/show_detail/seats/world/241101_ZEPP NAMBA_SEAT_2F.png",
    ],
    setlist: [
      "불놀이 (Flowerwork)",
      "Video Therapy",
      "Born to be - 미발매곡",
      "Kick-Ass",
      "Lupin",
      "Songbird",
      "Blue Moon",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "옥탑방 (Rooftop)",
      "그랬나봐 (I Think I Did)",
      "FLOWER FANTASY",
      "Love You Like That - 미발매곡",
      "굿밤 (GOOD BAM)",
      "ANYWAY",
      "4242",
      "Star",
      "꽃바람 (YOUTH)",
      "아 진짜요. (Oh really.)",
      "Moonshot",
      "Monster",
      "진짜가 나타났다 (The Real)",
      "Sunset",
      "폭망 (I Like You)",
      "놔 (Leave It)",
      "Run",
      "The World Is Mine",
      "그러니까 우리 (Don't Forget This)",
      "Stand By Me",
      "Blue Moon",
      "Songbird",
      "Kick-Ass"
    ],
    ootd: [
      "/image/show_detail/ootd/world/241101_HIDE-OUT_JAPAN_OOTD_01.jpeg",
      "/image/show_detail/ootd/world/241101_HIDE-OUT_JAPAN_OOTD_02.jpeg",
      "/image/show_detail/ootd/world/241101_HIDE-OUT_JAPAN_OOTD_03.jpeg",
    ],
  },
  {
    id: 1005,
    name: "2024 N.Flying LIVE HIDE-OUT in ASIA - Bangkok",
    address:
      "MCC HALL BANGKAE, 362 Kanchanaphisek Road, Bang Khae Nuea, Bang Khae, Bangkok 10160, Thailand",
    note: [""],
    capacity: "Approximately 5,000 people",
    seats: ["/image/show_detail/seats/world/241123_HIDE-OUT_BANGKOK_SEAT.jpg"],
    setlist: [""],
    ootd: [""],
  },
  {
    id: 1006,
    name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA - Kuala Lumpur",
    address:
      "Zepp Kuala Lumpur BBCC No.2, Jalan Hang Tuah, Bukit Bintang City Centre, 55100 Kuala Lumpur",
    note: [""],
    capacity: "Approximately 2,500 people",
    seats: ["/image/show_detail/seats/world/241201_HIDE-OUT_MY_SEAT.png"],
    setlist: [""],
    ootd: [""],
  },
  {
    id: 1007,
    name: "FNC KINGDOM - SING SING SING - Japan",
    address:
      "Makuhari Messe International Exhibition Halls 9-11 2-1 Nakase, Mihama-ku, Chiba 261-8550, Japan",
    note: [""],
    capacity: "Approximately 12,000 people",
    seats: [""],
    setlist: [""],
    ootd: [""],
  },
  {
    id: 1008,
    name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA - Macau",
    address: "Broadway Theatre, Broadway Macau, Estrada do Istmo, Cotai, Macau",
    note: [""],
    capacity: "Approximately 2,500 people",
    seats: ["/image/show_detail/seats/world/241222_HIDE-OUT_MACAU_SEAT.jpg"],
    setlist: [""],
    ootd: [""],
  },
  {
    id: 1009,
    name: "1st FNC BAND KINGDOM in TAOYUAN - Taiwan",
    address:
      "NTaoyuan Arena, No. 1, Section 1, Sanmin Road, Taoyuan District, Taoyuan City, Taiwan 330",
    note: [""],
    capacity: "Approximately 15,000 people",
    seats: [
      "/image/show_detail/seats/world/250104_THE TAOYUAN ARENA_SEAT_SUB_STAGE.jpg",
    ],
    setlist: [""],
    ootd: [""],
  },
  {
    id: 1010,
    name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA - TAIPEI",
    address: "TICC No. 1, Section 5, Xinyi Rd, Xinyi District, Taipei City, TAIPEI 110",
    note: [""],
    capacity: "Approximately 3,000 people",
    seats: ["/image/show_detail/seats/world/250209_HIDE-OUT_TAIPEI_SEAT.jpeg"],
    setlist: [""],
    ootd: [""],
  },
];
