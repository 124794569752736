export const showInfos = [
  {
    id: 1,
    name: "2024 N.Flying LIVE HIDE OUT",
    address: "서울 광진구 구천면로 20", // 예스24 라이브홀
    note: [
      "예스24라이브홀 전용 주차장 없음.",
      "인근 공영주차장이나 대중교통 이용 권장.",
    ],
    capacity: "약 2,000명 (스탠딩 기준)",
    seats: [
      "/image/show_detail/seats/2024/YES24_Live_Hall.png",
      "/image/show_detail/seats/2024/YES24_Live_Hall_official.png",
    ],
    setlist: [
      "불놀이 (Flowerwork)",
      "Video Therapy",
      "Born to be - 미발매곡",
      "Star",
      "꽃바람 (YOUTH)",
      "아 진짜요. (Oh really.)",
      "Blue Moon",
      "옥탑방 (Rooftop)",
      "그랬나봐 (I Think I Did)",
      "FLOWER FANTASY",
      "Love You Like That - 미발매곡",
      "굿밤 (GOOD BAM)",
      "ANYWAY",
      "4242",
      "네가 내 마음에 자리 잡았다 (Into You) - 미공개 신곡 스포",
      "Autumn Dream",
      "기가 막혀 (Awesome)",
      "Moonshot",
      "Monster",
      "진짜가 나타났다 (The Real)",
      "Run",
      "The World Is Mine",
      "그러니까 우리 (Don't Forget This)",
      "Stand By Me",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240607_HIDE-OUT_DAY1_OOTD.jpeg",
      "/image/show_detail/ootd/2024/240607_HIDE-OUT_DAY1_OOTD2.jpeg",
      "/image/show_detail/ootd/2024/240608_HIDE-OUT_DAY2_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240608_HIDE-OUT_DAY2_OOTD2.jpeg",
      "/image/show_detail/ootd/2024/240609_HIDE-OUT_DAY3_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240609_HIDE-OUT_DAY3_OOTD2.jpeg",
    ],
  },
  {
    id: 2,
    name: "2024 어썸뮤직페스티벌 in 대구",
    address: "대구 수성구 유니버시아드로42길 139", // 대구스타디움 보조경기장
    note: ["대구스타디움 내 주차장이 넓게 마련되어 있어 주차 가능 공간 충분."],
    capacity: "약 3,000명 ~ 최대 5,000명 수용 가능(임시 좌석 설치 기준)",
    seats: [],
    setlist: [
      "Songbird",
      "옥탑방 (Rooftop)",
      "피었습니다. (Into Bloom)",
      "아 진짜요. (Oh really.)",
      "Blue Moon",
      "Star",
      "팔불출 (Lovefool)",
      "Preview",
      "ANYWAY",
      "폭망 (I Like You)",
      "4242",
      "Sunset",
      "Flashback",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240616_DAEGU_AWESOME_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240616_DAEGU_AWESOME_OOTD2.jpeg",
    ],
  },
  {
    id: 3,
    name: "22024 어썸스테이지 in Busan",
    address: "부산 해운대구 APEC로 55", // 벡스코 오디토리움
    note: ["벡스코 내 주차장 약 4,000대 주차 가능."],
    capacity: "약 4,000명",
    seats: [
      "/image/show_detail/seats/2024/BEXCO_Auditorium_awesome.png",
      "/image/show_detail/seats/2024/BEXCO_Auditorium_1F_official.png",
      "/image/show_detail/seats/2024/BEXCO_Auditorium_2F_official.png",
    ],
    setlist: [
      "Flashback",
      "Sober",
      "Moonshot",
      "Blue Moon",
      "피었습니다. (Into Bloom)",
      "굿밤 (GOOD BAM)",
      "팔불출 (Lovefool)",
      "Preview",
      "ANYWAY",
      "Star",
      "아 진짜요. (Oh really.)",
      "4242",
      "Autumn Dream",
      "폭망 (I Like You)",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "Endless Summer",
      "옥탑방 (Rooftop)",
      "Songbird",
      "Sunset",
      "뜨거운 감자 (Hot Potato)",
      "진짜가 나타났다 (The Real)",
      "놔 (Leave It)",
      "Stand By Me",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240630_BUSAN_AWESOME_STAGE_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240630_BUSAN_AWESOME_STAGE_OOTD2.jpeg",
    ],
  },
  {
    id: 4,
    name: "청춘썸머나잇 2024",
    address: "경기 고양시 일산서구 킨텍스로 217-60", // 일산 킨텍스
    note: ["킨텍스 내 대형 주차장 약 20,000대 주차 가능."],
    capacity: "최대 6,000명 이상 수용 가능",
    seats: [],
    setlist: [
      "Songbird",
      "Star",
      "Blue Moon",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "Endless Summer",
      "아 진짜요. (Oh really.)",
      "Sunset",
      "ANYWAY",
      "4242",
      "옥탑방 (Rooftop)",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240707_KINTEX_BLUESPRING_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240707_KINTEX_BLUESPRING_OOTD2.jpeg",
    ],
  },
  {
    id: 5,
    name: "Soundberry Festa’ 24",
    address: "서울 강서구 공항대로 376", // KBS 아레나
    note: [
      "KBS 아레나 건물 내 주차장이 있으나, 공간이 협소해 대중교통 이용을 권장.",
    ],
    capacity: "약 3,000명",
    seats: [],
    setlist: [
      "Star",
      "Songbird",
      "Preview",
      "ANYWAY",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "Firefly",
      "이 별 저 별 (Fate)",
      "Monster",
      "Video Therapy",
      "Blue Moon",
      "Endless Summer",
      "4242",
      "옥탑방 (Rooftop)",
      "진짜가 나타났다 (The Real)",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240721_SEOUL_SOUNDBERRY_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240721_SEOUL_SOUNDBERRY_OOTD2.jpeg",
    ],
  },
  {
    id: 6,
    name: "Soundberry Festa’ 24 - 부산",
    address: "부산 해운대구 APEC로 55", // 벡스코 제1전시장
    note: ["벡스코 내에 유료 주차 공간에 선착순으로 가능."],
    capacity: "약 4,000명 (공연에 따라 다름)",
    seats: [],
    setlist: [
      "Blue Moon",
      "그 밤 (The Night)",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "이 별 저 별 (Fate)",
      "Flashback",
      "Moonshot",
      "Video Therapy",
      "Monster",
      "굿밤 (GOOD BAM)",
      "아 진짜요. (Oh really.)",
      "Songbird",
      "옥탑방 (Rooftop)",
      "진짜가 나타났다 (The Real)",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240810_BUSAN_SOUNDBERRY_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240810_BUSAN_SOUNDBERRY_OOTD2.jpeg",
    ],
  },
  {
    id: 7,
    name: "LIVE ON BTOB X N.Flying",
    address: "서울 동대문구 경희대로 26", // 경희대학교 평화의 전당
    note: ["경희대학교 내 유료 주차 가능."],
    capacity: "약 4,500명",
    seats: [
      "/image/show_detail/seats/2024/KYUNGHEE_liveon.png",
      "/image/show_detail/seats/2024/KYUNGHEE_1F_official.png",
      "/image/show_detail/seats/2024/KYUNGHEE_2F_official.png",
      "/image/show_detail/seats/2024/KYUNGHEE_3F_official.png",
    ],
    setlist: [
      "Flashback",
      "Songbird",
      "파스텔리아 (Pastelia) - 미발매곡",
      "피었습니다. (Into Bloom)",
      "Star",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "Sunset",
      "아 진짜요. (Oh really.)",
      "4242",
      "Video Therapy",
      "Monster",
      "Blue Moon",
      "옥탑방 (Rooftop)",
      "진짜가 나타났다 (The Real)",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240817_LIVEON_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240817_LIVEON_OOTD2.jpeg",
    ],
  },
  {
    id: 8,
    name: "Someday Festival 2024",
    address: "서울 마포구 상암동 482-132", // 서울 난지한강공원
    note: ["공원 내 유료 주차 공간 이용 가능 (주말에는 혼잡할 수 있음)."],
    capacity: "약 5,000명 이상 (주최 행사에 따라 다름)",
    seats: [],
    setlist: [
      "Songbird",
      "Endless Summer",
      "Star",
      "팔불출 (Lovefool)",
      "Preview",
      "ANYWAY",
      "Sunset",
      "아 진짜요. (Oh really.)",
      "4242",
      "Blue Moon",
      "옥탑방 (Rooftop)",
      "Stand by me",
      "놔 (Leave It)",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240907_01_SOMEDAY_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240907_01_SOMEDAY_OOTD2.jpeg",
    ],
  },
  {
    id: 9,
    name: "2024 파크콘서트",
    address: "경기 성남시 분당구 성남대로 550", // 분당 중앙공원 야외공연장
    note: ["공원 내 무료 주차 가능 (주말 및 행사 시 혼잡)."],
    capacity: "고정 객석 480명, 잔디 객석 최대 11,000명 수용 가능",
    seats: [],
    setlist: [
      "Blue Moon",
      "Sunset",
      "폭망 (I Like You)",
      "아 진짜요. (Oh really.)",
      "4242",
      "Preview",
      "ANYWAY",
      "굿밤 (GOOD BAM)",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "옥탑방 (Rooftop)",
      "Flashback",
      "놔 (Leave It)",
      "Songbird",
    ],
    ootd: ["/image/show_detail/ootd/2024/240907_02_PARKCON_OOTD.jpeg"],
  },
  {
    id: 10,
    name: "미유페(Me+Youth Festival)",
    address: "서울 마포구 상암동 482-132", // 난지한강공원 젊음의광장
    note: ["공원 내 유료 주차 공간 이용 가능 (주말에는 혼잡할 수 있음)."],
    capacity: "약 5,000명 이상 (주최 행사에 따라 다름)",
    seats: [],
    setlist: [
      "UP ALL NIGHT",
      "FLOWER FANTASY",
      "피었습니다. (Into Bloom)",
      "아 진짜요. (Oh really.)",
      "ANYWAY",
      "옥탑방 (Rooftop)",
      "Blue Moon",
      "Sunset",
      "Songbird",
      "아 진짜요. (Oh really.)",
      "ANYWAY",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240922_MEYOUTH_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240922_MEYOUTH_OOTD2.jpeg",
    ],
  },
  {
    id: 11,
    name: "경기대학교 수원캠퍼스 축제",
    address: "경기 수원시 영통구 광교산로 154-42",
    note: ["캠퍼스 내 주차 가능."],
    capacity: "", // 행사는 수용인원 불필요
    seats: [],
    setlist: [
      "Star",
      "Blue Moon",
      "아 진짜요. (Oh really.)",
      "4242",
      "Autumn Dream",
      "피었습니다. (Into Bloom)",
      "그랬나봐 (I Think I Did)",
      "Flashback",
      "옥탑방 (Rooftop)",
      "놔 (Leave It)",
      "진짜가 나타났다 (The Real)",
      "ANYWAY",
      "Sunset",
      "Songbird",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240926_KYONGGIUNIV_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240926_KYONGGIUNIV_OOTD2.jpeg",
    ],
  },
  {
    id: 12,
    name: "계명대학교 성서캠퍼스 축제",
    address: "대구 달서구 신당동 산40-1",
    note: [
      "캠퍼스 내 주차는 가능하나, 주차 공간이 제한적일 수 있음. 대중교통 이용을 권장.",
    ],
    capacity: "", // 행사는 수용인원 불필요
    seats: [],
    setlist: [
      "Songbird",
      "Blue Moon",
      "Preview",
      "ANYWAY",
      "아 진짜요. (Oh really.)",
      "4242",
      "Video Therapy",
      "Monster",
      "옥탑방 (Rooftop)",
      "Flashback",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240927_KYEMYEONG_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240927_KYEMYEONG_OOTD2.jpeg",
    ],
  },
  {
    id: 13,
    name: "70th 백제문화제",
    address: "충남 부여군 규암면 백제문로 455", // 백제문화단지 주무대
    note: [
      "대규모 주차 공간이 마련되어 있으며, 행사 기간 동안 주차 요금은 별도로 부과될 수 있음.",
    ],
    capacity: "약 10,000명 이상 수용 가능", // 행사는 수용인원 불필요
    seats: [],
    setlist: [
      "옥탑방 (Rooftop)",
      "Songbird",
      "STARLIGHT",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "Blue Moon",
      "Star - 1절만",
      "Sunset",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240928_BAEKJAE_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240928_BAEKJAE_OOTD2.jpeg",
      "/image/show_detail/ootd/2024/240928_BAEKJAE_OOTD3.jpeg",
    ],
  },
  {
    id: 14,
    name: "그린캠프페스티벌 2024 인천",
    address: "인천 연수구 센트럴로 350 달빛축제공원", // 송도달빛축제공원
    note: [
      "송도달빛축제공원 내 주차 공간 있으나, 행사 기간 중에는 혼잡할 수 있으므로 대중교통 이용 권장.",
    ],
    capacity: "약 50,000명 이상 수용 가능", // 구체적 명시 없음.
    seats: [],
    setlist: [
      "Stand By Me",
      "Blue Moon",
      "Autumn Dream",
      "아 진짜요. (Oh really.)",
      "Sunset",
      "Video Therapy",
      "Monster",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "굿밤 (GOOD BAM)",
      "옥탑방 (Rooftop)",
      "너 없는 난 (Fall With You)",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/240929_GREENCAMP_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/240929_GREENCAMP_OOTD2.jpeg",
    ],
  },
  {
    id: 15,
    name: "한양대학교 ERICA 축제",
    address: "경기 안산시 상록구 한양대학로 55 한양대학교 에리카캠퍼스",
    note: ["캠퍼스 내 유료 주차 공간 운영되나, 대중교통 이용을 권장."],
    capacity: "", // 행사는 수용인원 불필요
    seats: [],
    setlist: [
      "Flashback",
      "Blue Moon",
      "스물다섯, 스물하나 (Twenty five, Twenty one)",
      "Video Therapy",
      "Monster",
      "Star",
      "Sunset",
      "아 진짜요. (Oh really.)",
      "4242",
      "놔 (Leave It)",
      "옥탑방 (Rooftop)",
      "ANYWAY",
      "Autumn Dream",
      "Songbird",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/241002_HYERICAUNIV_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/241002_HYERICAUNIV_OOTD2.jpeg",
    ],
  },
  {
    id: 16,
    name: "2024 남원 국제드론제전 with 로봇", // 남원종합스포츠타운 주경기장
    address: "전북 남원시 충정로 341",
    note: [
      "종합스포츠타운 내 주차 공간 있으나 행사의 규모에 따라 주차 공간 부족할 수 있음.",
    ],
    capacity: "", // 행사는 수용인원 불필요
    seats: [],
    setlist: [
      "Songbird",
      "Blue Moon",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "아 진짜요. (Oh really.)",
      "옥탑방 (Rooftop)",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/241003_DRONE-FESTA_OOTD1.png",
      "/image/show_detail/ootd/2024/241003_DRONE-FESTA_OOTD2.png",
    ],
  },
  {
    id: 17,
    name: "2024 부산국제록페스티벌", // 삼락생태공원
    address: "부산 사상구 삼락동 29-46",
    note: [
      "공원 내 유료 주차장 있으나, 주차 공간이 다소 협소하여 주말이나 행사 시에는 혼잡할 수 있음.",
      "대중교통을 이용 권장.",
    ],
    capacity: "약 10,000명 이상 수용 가능", // 행사는 수용인원 불필요
    seats: [],
    setlist: [
      "너 없는 난 (Fall With You)",
      "Songbird",
      "Moonshot",
      "Sober",
      "Monster",
      "Blue Moon",
      "옥탑방 (Rooftop)",
      "Flashback",
      "Shooting Star",
      "Stand By Me",
      "Sunset",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/241004_BUSANROCK_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/241004_BUSANROCK_OOTD2.jpeg",
    ],
  },
  {
    id: 18,
    name: "고창모양성제 - MZ 업고 뛰어!", // 고창읍성 앞 특설무대
    address: "전북특별자치도 고창군 고창읍 모양성로 1",
    note: [
      "고창읍성주차장 주차비 무료 (행사기간 혼잡)",
      "인근 고창 공영주차장 또는 고창전통시장 공영주차장 이용 추천.",
    ],
    capacity: "", // 행사는 수용인원 불필요
    seats: [],
    setlist: [
      "Blue Moon",
      "The World Is Mine",
      "피었습니다. (Into Bloom)",
      "Autumn Dream",
      "아 진짜요. (Oh really.)",
      "4242",
      "놔 (Leave It)",
      "옥탑방 (Rooftop)",
      "Sunset",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/241012_GOCHANG_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/241012_GOCHANG_OOTD2.jpeg",
    ],
  },
  {
    id: 19,
    name: "2024 어썸스테이지 in Seoul", // 연세대학교 대강당
    address: "서울 서대문구 연세로 50",
    note: [
      "연세대 정문 옆 지하주차장(백양로 지하주차장) 917대 수용 가능.",
      "/image/show_detail/note/2024/YONSEI_PARKING_INFO.png",
    ],
    capacity: "1,600명",
    seats: [
      "/image/show_detail/seats/2024/YONSEI_awesome.png",
      "/image/show_detail/seats/2024/YONSEI_1F_official.jpg",
      "/image/show_detail/seats/2024/YONSEI_2F_official_01.png",
    ],
    setlist: [
      "Moonshot",
      "Sober",
      "Moon & Cheese",
      "그 밤 (The Night)",
      "빈집 (Zip.)",
      "너에게 (To You)",
      "폭망 (I Like You)",
      "Autumn Dream",
      "Blue Moon",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "Preview",
      "ANYWAY",
      "Shooting Star",
      "이 별 저 별 (Fate)",
      "Star",
      "STARLIGHT",
      "파스텔리아 (Pastelia) - 미발매곡",
      "아 진짜요. (Oh really.)",
      "4242",
      "The World Is Mine",
      "그러니까 우리 (Don't Forget This)",
      "Sunset",
      "놔 (Leave It)",
      "Songbird",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/241013_AWESOME_STAGE_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/241013_AWESOME_STAGE_OOTD2.jpeg",
    ],
  },
  {
    id: 20,
    name: "그랜드 민트 페스티벌 2024", // 올림픽공원 88잔디마당
    address: "서울 송파구 올림픽로 424",
    note: [
      "올림픽공원 주차장 이용이 가능하나, 만차 가능성 높아 대중교통 이용 권장.",
      "필요 시 송파구청 주차장이나 잠실역 공영주차장 등의 대체 주차장 확인.",
    ],
    capacity:
      "전체 수용 인원 충족 시 매진 : 88잔디마당 - 10,000명 한정, 88호수수변무대 - 2,000명 한정",
    seats: [],
    setlist: [
      "Shooting Star",
      "Sober",
      "Moonshot",
      "Star",
      "Preview",
      "Autumn Dream",
      "Firefly",
      "이 별 저 별 (Fate)",
      "아 진짜요. (Oh really.)",
      "4242",
      "Blue Moon",
      "옥탑방 (Rooftop)",
      "Sunset",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/241027_GRAND_MINT_FESTIVAL_OOTD1.jpeg",
      "/image/show_detail/ootd/2024/241027_GRAND_MINT_FESTIVAL_OOTD2.jpeg",
      "/image/show_detail/ootd/2024/241027_GRAND_MINT_FESTIVAL_OOTD3.jpeg",
      "/image/show_detail/ootd/2024/241027_GRAND_MINT_FESTIVAL_OOTD4.jpeg",
    ],
  },
  {
    id: 21,
    name: "한수원 뮤직 페스티벌 &lt;씨어터&gt; - 경주", // 경주예술의전당 화랑홀
    address: "경상북도 경주시 알천북로 1 경주예술의전당 1층",
    note: [
      "공연 또는 전시 관람 시 경주예술의전당 제 1주차장 무료 개방",
      "기타 무료주차 : 공연장 서쪽 주차장 - 60대 / 공연장 인근 시민체육관 공영주차장 - 100대",
      "/image/show_detail/note/2024/GYEONGJU_FOUNDATION_PARKING.jpg",
    ],
    capacity: "1,053 석",
    seats: [
      "/image/show_detail/seats/2024/GYEONGJU_ARTCENTER_SEAT.jpg",
      "/image/show_detail/seats/2024/GYEONGJU_ARTCENTER_SEAT_1F.png",
      "/image/show_detail/seats/2024/GYEONGJU_ARTCENTER_SEAT_2F.png",
    ],
    setlist: [
      "Blue Moon",
      "Autumn Dream",
      "Firefly",
      "이 별 저 별 (Fate)",
      "옥탑방 (Rooftop)",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "Preview",
      "굿밤 (GOOD BAM)",
      "아 진짜요. (Oh really.)",
      "4242",
      "ANYWAY",
      "Songbird",
      "Sunset",
      "Shooting Star",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/241108_GYEONGJU_ARTCENTER_OOTD_01.jpeg",
      "/image/show_detail/ootd/2024/241108_GYEONGJU_ARTCENTER_OOTD_02.jpeg",
    ],
  },
  {
    id: 22,
    name: "2024 어썸스테이지 [ N.Flying ] - 부산", // 부산 벡스코 오디토리움
    address: "부산 해운대구 APEC로 55",
    note: ["벡스코 내 주차장 약 4,000대 주차 가능."],
    capacity: "약 4,000명",
    seats: [
      "/image/show_detail/seats/2024/BEXCO_Auditorium_awesome_241109.png",
      "/image/show_detail/seats/2024/BEXCO_Auditorium_1F_official.png",
      "/image/show_detail/seats/2024/BEXCO_Auditorium_2F_official.png",
    ],
    setlist: [
      "Moonshot",
      "Sober",
      "Tree",
      "Moon & Cheese",
      "그 밤 (The Night)",
      "빈집 (Zip.)",
      "너에게 (To You)",
      "폭망 (I Like You)",
      "Autumn Dream",
      "Blue Moon",
      "네가 내 마음에 자리 잡았다 (Into You)",
      "Preview",
      "ANYWAY",
      "Shooting Star",
      "이 별 저 별 (Fate)",
      "Star",
      "STARLIGHT",
      "파스텔리아 (Pastelia) - 미발매곡",
      "아 진짜요. (Oh really.)",
      "4242",
      "The World Is Mine",
      "Sunset",
      "Songbird",
      "파란 배경",
      "Blue Moon",
      "옥탑방 (Rooftop)",
    ],
    ootd: [
      "/image/show_detail/ootd/2024/241109_BUSAN_OOTD_01.jpeg",
      "/image/show_detail/ootd/2024/241109_BUSAN_OOTD_02.jpeg",
    ],
  },
  {
    id: 23,
    name: "LOVE IN SEOUL 2024 - 엔플라잉", // 블루스퀘어 마스터카드홀
    address: "서울 용산구 이태원로 294 블루스퀘어 마스터카드홀",
    note: [
      "블루스퀘어 주차장은 상시 만차되니 대중교통 이용 적극 권장 (인근주차장 : 한강진역 공영주차장)",
      "6호선 한강진역 2번 ~ 3번 출구 사이 지하철역 통로 연결",
    ],
    capacity: "스탠딩형 : 최대 2,800명 수용 (1층 2,400명, 2층 381석)",
    seats: [
      "/image/show_detail/seats/2024/BLUE_SQUARE_MASTER_CARD_HALL_SEAT.png",
      "/image/show_detail/seats/2024/BLUE_SQUARE_MASTER_CARD_HALL_SEAT_1F.png",
      "/image/show_detail/seats/2024/BLUE_SQUARE_MASTER_CARD_HALL_SEAT_2F.png",
    ],
    setlist: [""],
    ootd: [""],
  },
];
